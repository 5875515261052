<template>
  <default-loader class="vms-loader__visdom-shield--full"></default-loader>
</template>

<script>
import DefaultLoader from '@components/loaders/default-loader'
import { mapActions } from 'vuex'

export default {
  components: {
    DefaultLoader,
  },

  created() {
    this.signOut()
  },

  methods: {
    ...mapActions({
      signOut: 'auth/signOut',
    }),
  },
}
</script>
